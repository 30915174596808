import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-visitor-count',
  templateUrl: './visitor-count.component.html',
  styleUrls: ['./visitor-count.component.css']
})
export class VisitorCountComponent implements OnInit {


  @Input('type') type : string;

  counters : {
    social : 0,
    company : 0,
    business : 0
  }

  visitors : number = 0;

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {

    this.loadVisitor()

  }


  loadVisitor(){

    this.dataService.__post('/counter',{})
    .subscribe(
      (data) => {
        this.visitors = data[this.type]
      }
    )

  }

}
