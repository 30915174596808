import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';
import { environment } from '../environments';


var stripe = Stripe("pk_live_51IZHKfIHBUjxmoNTWnVkgCzGu8XCb94wcvCcHq0CiBwDGpk02HuB3Y5DjQEB1Lp4umdzH7YRlDa0zMHNGxcJfKLK00BtwycFu1");
// var stripe = Stripe("pk_test_51Kt3rNH9rAL64Q2MM2y9KrOvA6jPXeMPwndwGEy0WU9im9itJ2oMMpivYZp01RMZk0WvfPBTm0vAbv02WS6fPTev00pv5DaFwb");

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  url:any
  user : any = false
  profile : any = false
  cardCaptureReady = false
  isPaymentLoading : boolean = false;
  isSubscriptionLoading : boolean = true
  path : any = environment.ASSETS_URL

  activeTab : number = 1;

  @ViewChild('closeModel') closeModel: ElementRef<HTMLElement>;
  @ViewChild('subscribeButton') subscribeButton: ElementRef<HTMLElement>;
  


  subscriptions : Array<any> = [];

  paymentUser :  any = {
    customerName : '',
    customerEmail : ''
  }

  activePlan : string = '';
  clickavailables : any = 0;
  planClicks : any = 0;
  subscriptionEnd : any = '';
  subscriptionEndPlan : any = '';
  totalClicks : any = 0;

  constructor(
    private dataService: DataService,
    private Auth: AuthService,
    private activatedRouter : ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Profile')
    this.user = this.Auth.getUser();
    this.getUserSubscriptions();

    this.activatedRouter.queryParams.subscribe(params => {
      this.url = params['type'];
      if(this.url && this.url == 's'){
        this.activeTab= 3;
      }
    });

    this.clickavailables = this.dataService.getTemp('AVAILABLE_CLICKS');
    this.totalClicks = this.clickavailables;
    this.subscriptionEnd = this.dataService.getTemp('subscriptionEnd');
    this.subscriptionEndPlan = this.subscriptionEnd;

  }

  ngAfterViewInit(){

    if(this.url && this.url == 's'){
      this.subscribeButton.nativeElement.click();
    }

  }

  onProfileChange(event) {
    this.profile = event.target.files[0];
  }

  onChangePlan(plan){

    if(isNaN(this.clickavailables)){
      this.planClicks = 0;
      this.clickavailables = 0;
    }

    if(!this.subscriptionEnd){
      this.subscriptionEnd = moment()
    } 

    var currantSubscriptionEnd =  moment(this.subscriptionEnd);
   
    if(currantSubscriptionEnd.diff(moment()) < 1){
      currantSubscriptionEnd = moment()
      this.clickavailables = 0;
    }

    this.activePlan = plan;
    this.subscriptionEndPlan = currantSubscriptionEnd.add(365, 'days');

    switch(plan){


      case 'SILVER' : 
        this.totalClicks = +this.clickavailables + 4000;
        break;

      case 'GOLDEN' : 
      this.totalClicks = +this.clickavailables + 8000;
        break;

      case 'PLATINUM' : 
        this.totalClicks = 'UNLIMITED';
        break;

      default :
        this.dataService.showAlert('error','Error','Please select valid plan!')
        break;


    }



  }

  onUpdateProfile(formData) {

    const data = new FormData();

    if(formData.value.name.trim() == '' || formData.value.email.trim() == '' || formData.value.username.trim() == '') {
      this.dataService.showAlert('error', 'Error', 'Please fill all the fields')
      return;
    }

    if(this.profile != false) data.append('profile', this.profile);
    data.append('name', formData.value.name)
    data.append('email', formData.value.email)
    data.append('username', formData.value.username)

    this.dataService.__post('/update-profile', data, '#updateProfile')
    .subscribe(
      (success) => {
        this.Auth.setUser(success)
        this.Auth.setToken(success.token)
        this.dataService.showAlert('success', 'Success', 'Profile update successfully')
        window.location.href = environment.APP_PATH + '/profile'
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }
  
  onChangePassword(formData) {
    

    if(formData.value.password.trim() != formData.value.confirmPassword.trim()) {
      this.dataService.showAlert('error', 'Error', 'Password and confirm password not match')
      return false
    }

    this.dataService.__post('/change-password', formData.value, '#changePassword')
    .subscribe(
      (success) => {
        this.dataService.showAlert('success', 'Success', 'Password update successfully')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }

  onStripeInvalid( error: Error ){
    console.log('Validation Error', error)
  }

  onStripeError( error: Error ){
    console.error('Stripe error', error)
  }

  setPaymentMethod( token: stripe.paymentMethod.PaymentMethod ){
    console.log('Stripe Payment Method', token)
  }

  setStripeToken( token: stripe.Token ){
    
    this.isPaymentLoading = true;

    if(this.activePlan == ''){
      this.dataService.showAlert('error','Error','Pzlease choose your plan');
      this.isPaymentLoading = false;
      return false;
    }

    if(!token){
      this.dataService.showAlert('error','Error','Oops! Something went wrong! Please try again');
      this.isPaymentLoading = false;
      return false;

    }

    var data = {
      token : token.id,
      customerName : this.paymentUser.customerName,
      customerEmail : this.paymentUser.customerEmail,
      plan : this.activePlan
    }
    
    this.dataService.__post('/subscription/create-payment',data)
    .subscribe(
      (paymentSuccess) => {

        this.dataService.setTemp('AVAILABLE_CLICKS',paymentSuccess.clicks);
        this.dataService.setTemp('subscriptionEnd',paymentSuccess.expiring);

        window.location.href = '/profile';

        // this.subscriptions.unshift(paymentSuccess);
        // this.isPaymentLoading = false;

        // this.paymentUser = {
        //   customerName : '',
        //   customerEmail : '' 
        // }

        // let el: HTMLElement = this.closeModel.nativeElement;
        // el.click();

      },
      (error) => {
        this.dataService.showAlert('error','Error',error);
        this.isPaymentLoading = false;
      }
    )



  }

  setStripeSource( source: stripe.Source ){
    console.log('Stripe Source', source)
  }


  getUserSubscriptions(){


    this.dataService.__post('/subscriptions',{})
    .subscribe(
      (subscriptions) => {
        this.subscriptions = subscriptions;
        this.isSubscriptionLoading = false;
      },

      (error) => {
        this.dataService.showAlert('error','Error','Opps! Failed to load subscriptions');
      }
    )

    


  }

  downloadImage(img) {
    const imgUrl = img.src;
    const imgName = imgUrl.substr(imgUrl.lastIndexOf('/') + 1);
    this.httpClient.get(imgUrl, {responseType: 'blob' as 'json'})
      .subscribe((res: any) => {
        const file = new Blob([res], {type: res.type});

        // IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file);
          return;
        }

        const blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        link.download = imgName;

        // Version link.click() to work at firefox
        link.dispatchEvent(new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        }));

        setTimeout(() => { // firefox
          window.URL.revokeObjectURL(blob);
          link.remove();
        }, 100);
      });
  }

  

  
  createPaymentSession(){


    this.dataService.__post('/subscription/session',{})
    .subscribe(
      async (session) => {
        console.log('Session',session);
        this.dataService.setTemp('TC',session.token)
        const paymentResponse = await stripe.redirectToCheckout({ sessionId: session.id });
      },
      (error) => {
        console.log('error',error)
      }
    )


  }

}
