import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.css']
})
export class BusinessProfileComponent implements OnInit {

  businessCard : any = false
  loading : boolean = true;
  hasError : boolean = false;
  overlapClass : string = '';
  id : any = ''

  constructor(
    private dataService: DataService,
    private activatedRoute : ActivatedRoute
  ) { }


  ngOnInit() {

    this.activatedRoute.params.subscribe((params) => {
      this.id = params.token;
      this.getBusinessCard();
    })

  }

  async getBusinessCard () {

    const trackData : any = await this.dataService._trackData();

    this.dataService.__post('/user/business', {id : this.id, track : trackData})
    .subscribe(
      (success) => {
        setTimeout(()=>{
          this.loading = false;
          this.businessCard = success
        },2000)
      },
      (error) => {
        console.log('Error',error)
        this.loading = false;
        this.hasError = error;
      }
    )
  }

}
