import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { environment } from "../environments";

import {NgxImageCompressService} from 'ngx-image-compress';


@Component({
  selector: "app-dropzone",
  templateUrl: "./dropzone.component.html",
  styleUrls: ["./dropzone.component.css"],
})
export class DropzoneComponent implements OnInit {


  files: File[] = [];
  imageSrc: any;

  imageFile: any = '';
  croppedImage: any = '';


  transform : ImageTransform = {
    scale : 1
  }

  isImageCompressing : boolean = false;


  @ViewChild('cropImage') cropImage: ElementRef<HTMLElement>;


  @Output() onFileSelect = new EventEmitter();

  @Input('placeholder') placeholderImage : string;
  @Input('aspectRatio') aspectRatio : any;


  constructor(private imageCompress: NgxImageCompressService) {}


  ngOnInit(): void {


    if(this.placeholderImage){
      this.imageSrc = environment.ASSETS_URL + this.placeholderImage; 
    }

  }

  onSelect(event) {

    this.files = event.addedFiles;
    this.imageFile = this.files[0];

    let el: HTMLElement = this.cropImage.nativeElement;
    el.click();
    
  }

  onRemove() {
    this.files = [];
    this.imageSrc = false;
  }

  fileChangeEvent(event: any): void {
    this.imageFile = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    
  }


  base64ToFile(base64){
    var arr = base64.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), 
    n = bstr.length, 
    u8arr = new Uint8Array(n);
        
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }

    var file = new File([u8arr], new Date().getTime().toString() + '.png', {type:mime});
    return file;
  }

 
  async cropSelectedImage(){

    this.isImageCompressing = true;
    var file = this.base64ToFile(this.croppedImage);

    if(this.imageCompress.byteCount(this.croppedImage) > 3145728){

      var orientation = await this.imageCompress.getOrientation(file);
      this.imageCompress.compressFile(this.croppedImage, orientation).then(
      result => {
        
        var file = this.base64ToFile(result);
          const reader = new FileReader();
          reader.onload = e => this.imageSrc = reader.result;
          reader.readAsDataURL(file);
          this.files = [file];
          this.onFileSelect.emit(this.files);
          this.isImageCompressing = false;
      }
    );

    } else {

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
      this.files = [file];
      this.onFileSelect.emit(this.files);
      this.isImageCompressing = false;

    }

    

    
  }

  loadImageFailed() {
      // show message
  }

  changeRange(value){
    console.log('value',value);
    this.transform = {...this.transform,scale : value}
    console.log('transform',this.transform);
  }

}
