import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { throwError, Subject, Observable } from "rxjs";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import $ from "jquery";
import { environment } from "./environments";
import { Title } from "@angular/platform-browser";

import axios from "axios";

@Injectable({
  providedIn: "root",
})
export class DataService {
  selectedSocialMedias = new Subject<any>();

  constructor(
    private _http: HttpClient,
    private titleService: Title,
    private toastr: ToastrService
  ) {}

  localUrl: string = "http://localhost:9090/api/v1/front";
  liveUrl: string = "https://api.youscanme.com/api/v1/front";
  // liveUrl: string = 'http://192.168.1.4:9090/api/v1/front';
  apiUrl: string =
    window.location.origin.match("localhost") !== null
      ? this.localUrl
      : this.liveUrl;

  setTitle(title: string = "Home", main: string = "| youscanme") {
    this.titleService.setTitle(`${title} ${main}`);
  }

  _response(response: any) {
    if (response.code !== 200) {
      throw new Error(response.data);
    }

    return response.data;
  }

  _handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage =
        error.message || "Oops! Something went wrong, Please try again.";
    }
    return errorMessage;
  }

  __post(
    url: string,
    data: any,
    loaderElement: any = false,
    progressBarLine: any = false
  ) {
    var buttonName = "";

    if (loaderElement) {
      buttonName = $(loaderElement).text();
      $(buttonName).attr("disabled", "true");
      $(loaderElement).html(
        '<i class="fa fa-spin fa-spinner"></i> ' + buttonName
      );
    }

    var options: any = { headers: this._getHeaders() };

    // if(progressBarLine !== false){
    //   options.reportProgress = true;
    //   options.observe = 'events';

    //   $(progressBarLine).attr('style', 'width:1%');
    // }

    return this._http.post(`${this.apiUrl}${url}`, data, options).pipe(
      map((response: any) => {
        // Remove Loader

        if (loaderElement) {
          $(buttonName).removeAttr("disabled");
          $(loaderElement).find(".fa").remove();
          $(loaderElement).html(buttonName);
        }

        return this._response(response);
      }),
      catchError((error: any) => {
        // this._toast.error(error)

        if (loaderElement) {
          $(loaderElement).find(".fa").remove();
          $(loaderElement).html(buttonName);
        }

        return throwError(this._handleError(error));
      })
    );
  }

  _getHeaders() {
    var token = this.getToken();
    return new HttpHeaders({
      "X-Authenticated-user": token ? token : "unAuth",
    });
  }

  getToken() {
    return localStorage.getItem("X-Authenticated-user");
  }

  showAlert(type, title, message) {
    if (type == "success") {
      this.toastr.success(message, title);
    } else if (type == "error") {
      this.toastr.error(message, title);
    }
  }

  setSelectedSocialMedias(medias): void {
    this.selectedSocialMedias.next(medias);
  }

  public getSelectedSocialMedias(): Observable<any> {
    return this.selectedSocialMedias.asObservable();
  }

  setTemp(key, value) {
    localStorage.setItem(key, value);
  }

  getTemp(key) {
    return localStorage.getItem(key);
  }
  isMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  trackUserAgent() {
    var unknown = "-";

    // screen
    var screenSize = "";
    if (screen.width) {
      var width = screen.width ? screen.width : "";
      var height = screen.height ? screen.height : "";
      screenSize += "" + width + " x " + height;
    }

    // browser
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browser = navigator.appName;
    var version = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // Opera
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browser = "Opera";
      version = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf("OPR")) != -1) {
      browser = "Opera";
      version = nAgt.substring(verOffset + 4);
    }
    // Legacy Edge
    else if ((verOffset = nAgt.indexOf("Edge")) != -1) {
      browser = "Microsoft Legacy Edge";
      version = nAgt.substring(verOffset + 5);
    }
    // Edge (Chromium)
    else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
      browser = "Microsoft Edge";
      version = nAgt.substring(verOffset + 4);
    }
    // MSIE
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browser = "Microsoft Internet Explorer";
      version = nAgt.substring(verOffset + 5);
    }
    // Chrome
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browser = "Chrome";
      version = nAgt.substring(verOffset + 7);
    }
    // Safari
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browser = "Safari";
      version = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Firefox
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browser = "Firefox";
      version = nAgt.substring(verOffset + 8);
    }
    // MSIE 11+
    else if (nAgt.indexOf("Trident/") != -1) {
      browser = "Microsoft Internet Explorer";
      version = nAgt.substring(nAgt.indexOf("rv:") + 3);
    }
    // Other browsers
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browser = nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      if (browser.toLowerCase() == browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }
    // trim the version string
    if ((ix = version.indexOf(";")) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(" ")) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(")")) != -1) version = version.substring(0, ix);

    majorVersion = parseInt("" + version, 10);
    if (isNaN(majorVersion)) {
      version = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    // mobile version
    var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // system
    var os = unknown;
    var clientStrings = [
      { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
      { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
      { s: "Windows Vista", r: /Windows NT 6.0/ },
      { s: "Windows Server 2003", r: /Windows NT 5.2/ },
      { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
      { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
      { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
      { s: "Windows 98", r: /(Windows 98|Win98)/ },
      { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
      { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: "Windows CE", r: /Windows CE/ },
      { s: "Windows 3.11", r: /Win16/ },
      { s: "Android", r: /Android/ },
      { s: "Open BSD", r: /OpenBSD/ },
      { s: "Sun OS", r: /SunOS/ },
      { s: "Chrome OS", r: /CrOS/ },
      { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
      { s: "iOS", r: /(iPhone|iPad|iPod)/ },
      { s: "Mac OS X", r: /Mac OS X/ },
      { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: "QNX", r: /QNX/ },
      { s: "UNIX", r: /UNIX/ },
      { s: "BeOS", r: /BeOS/ },
      { s: "OS/2", r: /OS\/2/ },
      {
        s: "Search Bot",
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];
    for (var id in clientStrings) {
      var cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    var osVersion: any = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = "Windows";
    }

    switch (os) {
      case "Mac OS":
      case "Mac OS X":
      case "Android":
        osVersion =
          /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(
            nAgt
          )[1];
        break;

      case "iOS":
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion =
          osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
        break;
    }

    return {
      screen: screenSize,
      browser: browser,
      browserVersion: version,
      browserMajorVersion: majorVersion,
      mobile: mobile,
      os: os,
      osVersion: osVersion,
    };
  }

  _trackData() {
    return new Promise((resolve) => {
      var config: any = {
        method: "get",
        url: "https://ip-geo-location.p.rapidapi.com/ip/check?format=json&language=english",
        headers: {
          "x-rapidapi-host": "ip-geo-location.p.rapidapi.com",
          "x-rapidapi-key":
            "7d6f698abbmsh3f06c1e644b3959p16aec1jsnafda7e79bf2e",
        },
      };

      var track: any = this.trackUserAgent();

      const trackData = {
        country: "",
        countryCode: "",
        os: track.os,
        browser: track.browser,
        city: "",
        location: {},
      };

      axios(config)
        .then(function (response) {
          trackData.country = response.data.country.name;
          trackData.city = response.data.city.name;
          trackData.location = response.data.location;
          trackData.countryCode = response.data.country.code;
          resolve(trackData);
        })
        .catch(function (error) {
          resolve(trackData);
        });
    });
  }
}
