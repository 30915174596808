import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

import axios from 'axios';


@Component({
  selector: 'app-social-profile',
  templateUrl: './social-profile.component.html',
  styleUrls: ['./social-profile.component.css']
})
export class SocialProfileComponent implements OnInit {

  socialCard : any = false
  loading : boolean = true;
  hasError : boolean = false;
  id : any = ''

  constructor(
    private dataService: DataService,
    private activatedRoute : ActivatedRoute
  ) { }


  ngOnInit() {

    this.activatedRoute.params.subscribe((params) => {
      this.id = params.token;
      this.getSocialCard();
    })

  }

  async getSocialCard () {

    const trackData : any = await this.dataService._trackData();
    this.loadCard(trackData);

  }


  loadCard(track){

    this.dataService.__post('/user/social-card', {id : this.id,track : track})
    .subscribe(
      (success) => {
        setTimeout(()=>{
          this.loading = false;
          this.socialCard = success;
          this.dataService.setTitle(success.name + ' ' + success.surname)
        },2000)
      },
      (error) => {
        console.log('Error : ', error)
        this.loading = false;
        this.hasError = true;
      }
    )
  }

}
