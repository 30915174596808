import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DefaultComponent } from './layouts/default/default.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { BusinessCardComponent } from './business-card/business-card.component';
import { VCardComponent } from './v-card/v-card.component';
import { VcardProfileComponent } from './users/vcard-profile/vcard-profile.component';
import { SocialProfileComponent } from './users/social-profile/social-profile.component';
import { SocialComponent } from './social/social.component';
import { AuthGuard } from './auth.guard';
import { BusinessProfileComponent } from './users/business-profile/business-profile.component';
import { ResetLinkComponent } from './reset-link/reset-link.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SettingsComponent } from './settings/settings.component';
import { SuccessComponent } from './payment/success/success.component';
import { CancelComponent } from './payment/cancel/cancel.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { MasterAuthComponent } from './master-auth/master-auth.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { UrlRedirectComponent } from './url-redirect/url-redirect.component';

const routes: Routes = [
  {
    path : 'register',
    component : RegisterComponent
  },
  {
    path : 'login',
    component : LoginComponent
  },
  {
    path : 'reset-link',
    component : ResetLinkComponent
  },
  {
    path : 'forgot-password/:id',
    component : ForgotPasswordComponent
  },
  {
    path : 's/:token',
    component : SocialProfileComponent
  },
  {
    path : 'v/:token',
    component : VcardProfileComponent
  },
  {
    path : 'b/:token',
    component : BusinessProfileComponent
  },
  {
    path : 'master-auth/:token/:admin',
    component : MasterAuthComponent
  },
  {
    path : '',
    component : DefaultComponent,
    canActivate : [AuthGuard],
    children : [
      {
        path : '',
        redirectTo : '/social-profile',
        pathMatch : 'full'
      },
      {
        path : 'profile',
        component : ProfileComponent
      },
      {
        path : 'qr-code',
        component : QrCodeComponent
      },
      {
        path : 'social-profile',
        component : SocialComponent
      },
      {
        path : 'business-profile',
        component : VCardComponent
      },
      {
        path : 'url-redirect',
        component : UrlRedirectComponent
      },
      {
        path : 'company-profile',
        component : BusinessCardComponent
      },
      {
        path : 'settings',
        component : SettingsComponent
      },
      {
        path : 'statistics',
        component : DashboardComponent
      },
      {
        path : 'payment/success',
        component : SuccessComponent
      },
      {
        path : 'payment/cancel',
        component : CancelComponent
      }
    ]
  },
  {
    path : ':token',
    component : HomeComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
