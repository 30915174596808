import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/data.service';



@Component({
  selector: 'app-vcard-profile',
  templateUrl: './vcard-profile.component.html',
  styleUrls: ['./vcard-profile.component.css']
})
export class VcardProfileComponent implements OnInit {

  
  vCard : any = false
  loading : boolean = true;
  hasError : boolean = false;
  id : any = ''

  constructor(
    private dataService: DataService,
    private activatedRoute : ActivatedRoute
  ) { }


  ngOnInit() {

    this.activatedRoute.params.subscribe((params) => {
      this.id = params.token;
      this.getVCard();
    })

  }

  async getVCard () {

    const trackData = await this.dataService._trackData();
    this.dataService.__post('/user/v-card', {id : this.id, track : trackData})
    .subscribe(
      (success) => {
        setTimeout(()=>{
          this.loading = false;
          this.vCard = success
        },2000)
      },
      (error) => {
        console.log(error)
        this.loading = false;
        this.hasError = true;
      }
    );

  }

}
