import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  user: any = false;

  availabeClicks: any = false;
  subscriptionEnd: any = false;
  isLoading: boolean = false;
  type: any = '';

  @ViewChild('alertBox') alertBox: ElementRef<HTMLElement>

  constructor(
    private dataService: DataService,
    private Auth: AuthService
  ) { }

  ngOnInit() {
    this.user = this.Auth.getUser();

    this.isLoading = true

    this.dataService.__post('/subscription/currunt', {})
      .subscribe(
        (success) => {
          this.isLoading = false

          if(success.activePlanClicks && success.activePlanClicks == -1){

            this.availabeClicks = 'UNLIMITED';
            this.subscriptionEnd = success.subscriptionEnd;

          } else {
              if (success.remainClicks < 0) {
                success.remainClicks = 0;
              }
              this.availabeClicks = success.remainClicks;
              this.subscriptionEnd = success.subscriptionEnd;
          }

          this.dataService.setTemp('AVAILABLE_CLICKS',this.availabeClicks);
          this.dataService.setTemp('subscriptionEnd',this.subscriptionEnd);


        },
        (error) => {
          this.isLoading = false
          this.availabeClicks = 0;
          this.subscriptionEnd = -1;
          console.log('Error', error)
        }
      )

  }

  logout() {
    this.Auth.logout()
  }

  onSwitchToggle(event, type) {

    var _confirm = true;
    this.type = type;

    if (event.target.checked == true) {

      this.user.activeProfiles['socialProfile'] = false
      this.user.activeProfiles['businessProfile'] = false
      this.user.activeProfiles['vCardProfile'] = false
      this.user.activeProfiles['urlRedirect'] = false

      this.user.activeProfiles[type] = true;
      this.toggleProfileCard();

    } else {

      if (!this.user.activeProfiles['socialProfile'] && !this.user.activeProfiles['businessProfile'] && !this.user.activeProfiles['vCardProfile']&&!this.user.activeProfiles['urlRedirect']) {

        let el = this.alertBox.nativeElement
        el.click();
        return false;

      }

      this.user.activeProfiles[this.type] = false;
      this.toggleProfileCard();


    }

  }



  toggleProfileCard() {


    console.log('HERE');

    const data = new FormData();
    data.append('activeProfiles', JSON.stringify(this.user.activeProfiles))

    this.dataService.__post('/update-profile', data)
      .subscribe(
        (success) => {
          this.Auth.setUser(success)
          this.Auth.setToken(success.token)
        },
        (error) => {
          this.dataService.showAlert('error', 'Error', error)
        }
      )

  }

  cancelDeactive(){
    console.log('this.type',this.type)
    this.user.activeProfiles[this.type] = true;
  }



}
