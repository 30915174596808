import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { environment } from '../environments';
import { of, Subject, Subscription } from 'rxjs';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';


@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.css']
})


export class BusinessCardComponent implements OnInit {

  isLoading: any = false;
  path: any = environment.ASSETS_URL
  app_path: any = environment.APP_PATH
  BusinessCard : any = false;
  selectedImage : any = false;
  selectedSocialMedias : Array<any> = [];

  _removeSocialAccount : Subject<any> = new Subject();
  _moveSocialAccount: Subject<boolean> = new Subject();
  _removeSocialAccountUpdate : Subject<any> = new Subject();

  galerryImages : Array<any> = [];

  features : Array<any> = [
    {
      id : 0,
      name : 'Free WIFI Zone',
      icon : 'wifi.png',
      status : false
    },
    {
      id : 1,
      name : 'Wheel Chair',
      icon : 'wheelchair.png',
      status : false
    },
    {
      id : 2,
      name : 'Toilette',
      icon : 'couple.png',
      status : false
    },
    {
      id : 3,
      name : 'Market',
      icon : 'shopping-cart.png',
      status : false
    },
    {
      id : 4,
      name : 'Pets',
      icon : 'paw.png',
      status : false
    },
    {
      id : 5,
      name : 'Parking',
      icon : 'p.png',
      status : false
    },
    {
      id : 6,
      name : 'Bus',
      icon : 'bus.png',
      status : false
    },
    {
      id : 8,
      name : 'Car Rent',
      icon : 'car.png',
      status : false
    },
    {
      id : 9,
      name : 'Rooms',
      icon : 'hospital.png',
      status : false
    },
    {
      id : 10,
      name : 'Coffee Bar',
      icon : 'cup.png',
      status : false
    },
    {
      id : 11,
      name : 'Cocktails  Bar',
      icon : 'glass.png',
      status : false
    },
    {
      id : 12,
      name : 'Restaurant',
      icon : 'restaurant.png',
      status : false
    },
  ]

  subscription: Subscription;
  text : string = '';

  bannerImage : File;

  options = {
    types: [],
  }

  timeSlots : Array<any> = [
    '08:00 am',
    '08:30 am',
    '09:00 am',
    '09:30 am',
    '10:00 am',
    '10:30 am',
    '11:00 am',
    '11:30 am',
    '12:00 pm',
    '12:30 pm',
    '01:00 pm',
    '01:30 pm',
    '02:00 pm',
    '02:30 pm',
    '03:00 pm',
    '03:30 pm',
    '04:00 pm',
    '04:30 pm',
    '05:00 pm',
    '05:30 pm',
    '06:00 pm',
    '06:30 pm',
    '07:00 pm',
    '07:30 pm',
    '08:00 pm',
    '08:30 pm'
  ]


  openingHourse : Array<any> = [
    {
      key : 'monday',
      isWorkingDay : false,
      name : 'Monday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'tuesday',
      isWorkingDay : false,
      name : 'Tuesday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'wednesday',
      isWorkingDay : false,
      name : 'Wednesday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'thursday',
      isWorkingDay : false,
      name : 'Thursday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'friday',
      isWorkingDay : false,
      name : 'Friday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'saturday',
      isWorkingDay : false,
      name : 'Saturday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    },
    {
      key : 'Sunday',
      isWorkingDay : false,
      name : 'Sunday',
      openingHours : [
        {
          start : '08:00 am',
          end : '08:00 pm'
        }
      ]
    }
  ]

  address : any = false;
  location : Array<any> = []


  constructor(
    private dataService : DataService,
    private router: Router
  ) { 

    this.subscription = this.dataService.getSelectedSocialMedias()
    .subscribe((data) => {
      this.selectedSocialMedias = data;
    })

  }

  ngOnInit() {
    this.dataService.setTitle('Company Profile')
    this.getBusinessProfile(); 
    this.dataService.setTemp('avctiveMedias',JSON.stringify([]))
  }

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: any) {

    console.log('address',address)

    // Do some stuff
    this.location = [address.geometry.location.lat(),address.geometry.location.lng(),address.url]
    this.address = address.formatted_address;
    if(this.BusinessCard){
      this.BusinessCard.address = this.address;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectFeature(feature){
    feature.status = !feature.status
  }

  addNewTimeSlot(slot){
    slot.openingHours.push({
      start : '08:00 am',
      end : '08:00 pm'
    })
  }

  removeHourSlot(slot,i){
    console.log(slot.openingHours);
    slot.openingHours.splice(i,1);
  }

  changeWorkingDay(slot){
    slot.isWorkingDay = !slot.isWorkingDay
    // console.log(slot)
    // console.log(this.openingHourse, slot.isWorkingDay)
  }

  onFileSelect(files : Array<File>){
    this.bannerImage = files[0];
  }


  onGallerySelect(event){
    this.galerryImages = event;
  }

  onChangeOpeningTime(event,slot,index,type){
    console.log('event',event.target.value,slot)
    slot = type == 'open' ? slot.start = event.target.value : slot.end = event.target.value;
    console.log('openingHours',this.openingHourse);
  }


  getBusinessProfile () {
    this.dataService.__post('/business-card', {})
    .subscribe(
      (success) => {
        this.BusinessCard = success
        this.text = `${environment.APP_PATH}/b/${success._id}`;
        this.dataService.setTemp('avctiveMedias',JSON.stringify(this.BusinessCard.socialMedias))
        this.dataService.setSelectedSocialMedias(this.BusinessCard.socialMedias);
        this.openingHourse = success.openingHours

        success.features.forEach((feature) => {
          const index = this.features.findIndex((item) => item.id == feature.id)
          if(index != -1) this.features[index].status = true
        })
        
      },
      (error) => {
        console.log('Error : ', error)
      }
    )
  }

  onCreate(formData, type = 'create') {

    // if (!this.selectedImage && type == "create") {
    //   this.dataService.showAlert("error", "Error", "Please Select Image");
    //   return false;
    // }

    var data = new FormData();
    

    // if (this.selectedImage) data.append("banner", this.selectedImage);

    // Banner

    if(type == 'create') 
    {
      if(!this.bannerImage){
        this.dataService.showAlert('error','Error','Please select your banner image');
        return false;
      }
      data.append('banner', this.bannerImage)
    } else {
      if(this.bannerImage){
        data.append('banner', this.bannerImage);
      }
    }

    var totalSize = 0;

    for (var i = 0; i < this.galerryImages.length; i++) {
      if(!this.galerryImages[i].original){
        totalSize += this.galerryImages[i].size;
      } 
    }


    if((totalSize / 1048576) > 20){
      this.dataService.showAlert('error','Error',`Your selected files size is ${(totalSize / 1048576).toFixed(2)}MB and maximum allowed 20MB`);
      return false;
    }

    var oldGalleryImages = [];
    this.galerryImages.map(file => {
      if(file.original){
        oldGalleryImages.push(file.original);
      }else{
        data.append('gallery',file)
      }
    });

    if(oldGalleryImages.length !== 0){
      data.append('oldGalleryImages',JSON.stringify(oldGalleryImages));
    }
    

    // if(this.address) {
    //   data.append('address', this.address);
    // }

    if(this.location.length > 0) {
      data.append('location', JSON.stringify(this.location));
    }

    // Form Data
    Object.keys(formData.value).forEach(key => {
      data.append(key,formData.value[key]);
    });

    // Button

    const button1 = {
      'label' : formData.value.label,
      'url' : formData.value.buttonUrl
    }
    data.append('button', JSON.stringify(button1))

    // openingHours
    data.append('openingHours', JSON.stringify(this.openingHourse));
    
    // Social Medias
    var social : Array<any> = []

    for (var key in this.selectedSocialMedias) {
      social.push({
        name : this.selectedSocialMedias[key].name,
        placeholder : this.selectedSocialMedias[key].placeholder,
        icon : this.selectedSocialMedias[key].icon,
        mediaUrl : this.selectedSocialMedias[key].mediaUrl,
        url : formData.value[this.selectedSocialMedias[key]._id],
        _id : this.selectedSocialMedias[key]._id
      })
    }

    // if(!social.length){
    //   this.dataService.showAlert('error','Invalid Value','Please select atleast 1 social media')
    //   return false;
    // }
 
    data.append('socialMedias',JSON.stringify(social))

    //Features

    const selectedFeatures = this.features.filter((item) => item.status == true)

    data.append('features', JSON.stringify(selectedFeatures));

    // API call
    this.dataService.__post('/profile/business/create', data, '#addBusinessCard')
    .subscribe(
      (success) => {
        this.BusinessCard = success;
        this.dataService.setSelectedSocialMedias(success.socialMedias);
        window.location.reload();
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error['data'])
      }
    )
  }

  deleteCard(){

    if(!confirm('Are you sure you want to delete this Company Profile!')) return false;

    this.dataService.__post('/profile/business/delete',{},'#delete-card')
    .subscribe(
      success => {
        this.dataService.showAlert('success','Success','Company Profile Deleted Successfully');
        this.BusinessCard = false;
      },
      error => {
        this.dataService.showAlert('error','Error','Oops! Failed to delete your account')
      }
    )

  }

  listOrderChanged(event : any){
    this.selectedSocialMedias = [...event];
  }

  removeSocialAccount(item, type = 'create'){
    item.isSelected = true;
    if(type == 'create'){
      this._removeSocialAccount.next(item);
    }else{
      this._removeSocialAccountUpdate.next(item);
    }
  }

  moveSocialAccount(item, type = 'up') {
    item['isLoaded'] = false
    item['type'] = type
    this._moveSocialAccount.next(item);
  }


}

