import { Component, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-qr",
  templateUrl: "./qr.component.html",
  styleUrls: ["./qr.component.css"],
})
export class QrComponent implements OnInit {
  // QR
  @Input('text') text : string = "";
  render = "image";
  crisp = true;
  minVersion = 1;
  ecLevel = "H";
  size = 250;
  ratio = 1;
  fill = "#333333";
  back = "#ffffff";
  rounded = 0;
  quiet = 10;
  mode = "image";
  mSize = 30;
  mPosX = 50;
  mPosY = 50;
  mSize2 = 30;
  mPosX2 = 50;
  mPosY2 = 50;
  label = "";
  fontname = "Ubuntu";
  fontcolor = "#ff9818";
  fontoutline = true;
  imageAsCode = false;
  imageText = "";
  imgNativeElement = undefined;
  image = "";

  constructor() {}

  ngOnInit(): void {
    this.getBase64ImageFromUrl("/assets/images/qr.jpeg")
      .then((result: any) => {
        this.image = result.replace("data:image/jpeg;base64,", "");
      })
      .catch((err) => console.error(err));
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          resolve(reader.result);
        },
        false
      );
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }
}
