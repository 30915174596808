import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth.service";
import { DataService } from "../data.service";

@Component({
  selector: "app-url-redirect",
  templateUrl: "./url-redirect.component.html",
  styleUrls: ["./url-redirect.component.css"],
})
export class UrlRedirectComponent implements OnInit {
  isLoading: boolean = false;
  URLRedirect:any
  constructor(
    private dataService: DataService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
     this.URLRedirect  = this.auth.getUser();
     console.log('this.URLRedirect',this.URLRedirect)

     

  }

  onAddURLRedirect(url: any) {
    
  

    if (url.value.urlRedirect ===null) {
      this.dataService.showAlert("error", "Error", "Enter Your Website URL");
      return false
    }
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );

    var urls = pattern.test(url.value.urlRedirect);
    if (urls === false) {
      this.dataService.showAlert("error", "error", "Please enter a valid URL");
      return false
    }
    this.isLoading = true;
    this.dataService.__post("/update-profile", url.value, "#URL").subscribe(
      (urls) => {
        this.dataService.showAlert(
          "success",
          "success",
          "Redirect URL added successfully"
        );
        this.isLoading = false;
      },
      (error) => {
        this.dataService.showAlert("error", "Error", error);
        this.isLoading = false;
      }
    );
  }
}
