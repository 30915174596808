import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-master-auth',
  templateUrl: './master-auth.component.html',
  styleUrls: ['./master-auth.component.css']
})
export class MasterAuthComponent implements OnInit {

  url:any
  isLoading : boolean = false;
  id : string = '';
  admin : string = '';

  constructor(
    private dataService : DataService,
    private activatedRouter : ActivatedRoute,
    private authService : AuthService,
    private router : Router
  ) { }

  ngOnInit(): void {

    this.activatedRouter.queryParams.subscribe(params => {
      this.url = params['type'];
    });


    this.activatedRouter.paramMap.subscribe(params => {
      this.id = params.get('token');
      this.admin = params.get('admin');
    })
    

  }

  onLogin(formData){

    if(!formData.valid) return false;

    this.isLoading= true
    this.dataService.__post('/user/interface', {user: this.id,pass : formData.value.password, admin : this.admin})
    .subscribe(
      (success) => {
      
        this.isLoading= false;
        this.authService.setUser(success);
        this.authService.setToken(success.token);

        
        if(this.url && this.url == 's'){
          this.router.navigateByUrl('/profile?type=s');
        } else {
          this.router.navigateByUrl('/social-profile')
        }


      },
      (error) => {
        this.isLoading= false
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }

}
