import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user: any = false
  API_PATH : any = environment.API_URL

  availabeClicks : any = false;
  subscriptionEnd : any = false;
  isLoading: boolean = false;
  type: any = '';

  @ViewChild('toggle') toggle: ElementRef<HTMLElement>
  @ViewChild('alertBoxHeader') alertBoxHeader: ElementRef<HTMLElement>

  constructor(
    private Auth : AuthService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.user = this.Auth.getUser()
    this.isLoading = true

    this.dataService.__post('/subscription/currunt', {})
    .subscribe(
      (success) => {
        this.isLoading = false

        if(success.activePlanClicks && success.activePlanClicks == -1){

          this.availabeClicks = 'UNLIMITED';
          this.subscriptionEnd = success.subscriptionEnd;

        } else {
            if (success.remainClicks < 0) {
              success.remainClicks = 0;
            }
            this.availabeClicks = success.remainClicks;
            this.subscriptionEnd = success.subscriptionEnd;
        }

        this.dataService.setTemp('AVAILABLE_CLICKS',this.availabeClicks);
        this.dataService.setTemp('subscriptionEnd',this.subscriptionEnd);


      },
      (error) => {
        console.log('Error', error)
      }
    )

  }

  getProfile() {
    if (this.user.profile) {
      return environment.API_URL + '/images/' + this.user.profile
    } else {
      return environment.APP_PATH + '/assets/images/profile/' + this.user.name.trim().charAt(0).toUpperCase() + '.png'
    }
  }

  logout() {
    let el : HTMLElement = this.toggle.nativeElement
    el.click();
    this.Auth.logout()
  }

  clickMenuItem() {
    let el : HTMLElement = this.toggle.nativeElement
    el.click();
  }

  onSwitchToggleHeader(event, type) {

    var _confirm = true;
    this.type = type;

    if (event.target.checked == true) {

      this.user.activeProfiles['socialProfile'] = false
      this.user.activeProfiles['businessProfile'] = false
      this.user.activeProfiles['vCardProfile'] = false
      this.user.activeProfiles['urlRedirect'] = false
      
      

      this.user.activeProfiles[type] = true;
      this.toggleProfileCardHeader();

    } else {

      if (!this.user.activeProfiles['socialProfile'] && !this.user.activeProfiles['businessProfile'] && !this.user.activeProfiles['vCardProfile'] && !this.user.activeProfiles['urlRedirect']) {

        let el = this.alertBoxHeader.nativeElement
        el.click();
        return false;

      }
      this.user.activeProfiles[this.type] = false;
      this.toggleProfileCardHeader();


    }

  }



  toggleProfileCardHeader() {

    const data = new FormData();
    data.append('activeProfiles', JSON.stringify(this.user.activeProfiles))

    this.dataService.__post('/update-profile', data)
      .subscribe(
        (success) => {
          this.Auth.setUser(success)
          this.Auth.setToken(success.token)
        },
        (error) => {
          this.dataService.showAlert('error', 'Error', error)
        }
      )

  }

  cancelDeactiveHeader(){
    this.user.activeProfiles[this.type] = true;
  }


  
}


