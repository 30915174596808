import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Subject, Subscription } from "rxjs";
import { DataService } from "../data.service";
import { environment } from "../environments";

@Component({
  selector: "app-v-card",
  templateUrl: "./v-card.component.html",
  styleUrls: ["./v-card.component.css"],
})
export class VCardComponent implements OnInit {
  isLoading: any = false;
  path: any = environment.ASSETS_URL;
  VCard: any = false;
  selectedImage: any = false;
  subscription: Subscription;
  selectedSocialMedias: Array<any> = [];
  text: string = "";
  options : any = {};

  isFormUploading : any = false;
  

  // address_components : Array<any> = []
  address : string = '';
  location : Array<any> = [];

  galerryImages : Array<any> = [];


  _removeSocialAccount: Subject<boolean> = new Subject();
  _moveSocialAccount: Subject<boolean> = new Subject();
  _removeSocialAccountUpdate: Subject<boolean> = new Subject();

  constructor(private dataService: DataService, private router: Router) {
    this.subscription = this.dataService
      .getSelectedSocialMedias()
      .subscribe((data) => {
        this.selectedSocialMedias = data;
      });
  }

  ngOnInit() {
    this.dataService.setTitle("Business Card Profile");
    this.dataService.setTemp("avctiveMedias", JSON.stringify([]));
    this.getVCard();
  }


  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: any) {
    

    // Do some stuff
    this.location = [address.geometry.location.lat(),address.geometry.location.lng(),address.url]
    this.address = address.formatted_address;
    if(this.VCard){
      this.VCard.address = this.address;
    }
    
    
    
  }

  onFileSelect(files: Array<File>) {
    this.selectedImage = files[0];
  }

  onGallerySelect(event){
    this.galerryImages = event;
  }

  getVCard() {
    this.dataService.__post("/v-card", {}).subscribe(
      (success) => {
        this.VCard = success;
        this.text = `${environment.APP_PATH}/v/${success._id}`;
        if (this.VCard.socialMedias) {
          this.dataService.setTemp(
            "avctiveMedias",
            JSON.stringify(this.VCard.socialMedias)
          );
          this.dataService.setSelectedSocialMedias(this.VCard.socialMedias);
        }
      },
      (error) => {
        console.log("Error : ", error);
      }
    );
  }

  onCreate(formData, type = "create") {
    
    if (!this.selectedImage && type == "create") {
      this.dataService.showAlert("error", "Error", "Please Select Image");
      return false;
    }
    var data = new FormData();
    if (this.selectedImage) data.append("image", this.selectedImage);


    var totalSize = 0;

    for (var i = 0; i < this.galerryImages.length; i++) {
      if(!this.galerryImages[i].original){
        totalSize += this.galerryImages[i].size;
      } 

    }


    if((totalSize / 1048576) > 20){
      this.dataService.showAlert('error','Error',`Your selected files size is ${(totalSize / 1048576).toFixed(2)}MB and maximum allowed 20MB`);
      return false;
    }


    var oldGalleryImages = [];


    this.galerryImages.map(file => {
      
      if(file.original){
        oldGalleryImages.push(file.original);
      }else{
        data.append('gallery',file)
      }

    });


    if(oldGalleryImages.length !== 0){
      data.append('oldGalleryImages',JSON.stringify(oldGalleryImages));
    }


    if(this.location.length > 0) {
      data.append('location', JSON.stringify(this.location)); 
    }

    Object.keys(formData.value).forEach((key) => {
      data.append(key, formData.value[key]);
    });

    data.append("type", type);

    var social: Array<any> = [];

    for (var key in this.selectedSocialMedias) {
      social.push({
        name : this.selectedSocialMedias[key].name,
        placeholder : this.selectedSocialMedias[key].placeholder,
        icon : this.selectedSocialMedias[key].icon,
        mediaUrl : this.selectedSocialMedias[key].mediaUrl,
        url : formData.value[this.selectedSocialMedias[key]._id],
        _id : this.selectedSocialMedias[key]._id
      })
    }

    if(social.length > 0) {
      data.append('socialMedias', JSON.stringify(social)); 
    }
    
    // console.log('social')

    // if (!social.length) {
    //   this.dataService.showAlert(
    //     "error",
    //     "Invalid Value",
    //     "Please select atleast 1 social media"
    //   );
    //   return false;
    // }


    console.log('data',data);


    this.dataService.__post("/v-card/create", data, "#addVCard",'#vCradProgress').subscribe(
      (success) => {
        this.VCard = success;
        this.dataService.setSelectedSocialMedias(success.socialMedias);
        window.location.reload();
      },
      (error) => {
        this.dataService.showAlert("error", "Error", error);
      }
    );
  }

  deleteCard() {
    if (!confirm("Are you sure you want to delete this V-Card!")) return false;

    this.dataService.__post("/v-card/delete", {}, "#delete-vcard").subscribe(
      (success) => {
        this.dataService.showAlert(
          "success",
          "Success",
          "Business Card Profile Deleted Successfully"
        );
        this.VCard = false;
      },
      (error) => {
        this.dataService.showAlert(
          "error",
          "Error",
          "Oops! Failed to delete your account"
        );
      }
    );
  }

  listOrderChanged(event : any){
    this.selectedSocialMedias = [...event];
  }


  removeSocialAccount(item, type = 'create'){
    item.isSelected = true;
    if(type == 'create'){
      this._removeSocialAccount.next(item);
    }else{
      this._removeSocialAccountUpdate.next(item);
    }
  }

  moveSocialAccount(item, type = 'up') {
    item['isLoaded'] = false
    item['type'] = type
    this._moveSocialAccount.next(item);
  }

}
