import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';

import * as crypto from 'crypto-js';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(
    private dataService : DataService,
    private router: Router,
    private Auth: AuthService
  ) { }

  url : any = false

  passwordValidation =  {
    length : false,
    uppercase : false,
    alphanumeric : false,
    special : false

  }

  ngOnInit() {
    this.dataService.setTitle('Registration')
    const u = localStorage.getItem('URLToken');
    if(!u){
      // this.router.navigateByUrl('/login');
      return false;
    }
    var decryptedBytes = crypto.AES.decrypt(u,"6YI7TFZVRT");
    this.url = decryptedBytes.toString(crypto.enc.Utf8);
  }

  onSubmit(formData) {

    if(formData.value.password != formData.value.confirmPassword) {
      this.dataService.showAlert('error', 'Error', 'Password and confirm password not match')
      return;
    }

   
    if(!this.passwordValidation.length || !this.passwordValidation.uppercase || !this.passwordValidation.special || !this.passwordValidation.alphanumeric){
      this.dataService.showAlert('error', 'Error', 'Please choose strong password');
      return;
    }

    if(!this.url) return

    formData.value.url = this.url
    this.dataService.__post('/user/create', formData.value)
    .subscribe(
      (success) => {
        this.router.navigateByUrl('/login')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }

  checkPassword(value){

    if(value.length >= 8){
      this.passwordValidation.length = true;
    } else {
      this.passwordValidation.length = false;
    }

   
    if(value.match(/[a-zA-Z0-9]/g)){
      this.passwordValidation.alphanumeric = true;
    } else {
      this.passwordValidation.alphanumeric = false;
    }


    if(value.match(/[$@$!%*?&]/g)){
      this.passwordValidation.special = true;
    } else {
      this.passwordValidation.special = false;
    }

    if(value.match(/[A-Z]/g)){
      this.passwordValidation.uppercase = true;
    } else {
      this.passwordValidation.uppercase = false;
    }



  }

}
