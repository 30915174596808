import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ShareService } from 'ngx-sharebuttons';
import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';


import $ from 'jquery';


@Component({
  selector: 'app-social-profile-card',
  templateUrl: './social-profile-card.component.html',
  styleUrls: ['./social-profile-card.component.css']
})


export class SocialProfileCardComponent implements OnInit {


  @Input('profile') profile : any;
  @Input('waveClass') waveClass : any = '';
  IMAGE_URL : string = environment.ASSETS_URL;
  APP_URL : string = environment.APP_PATH;
  PROFILE_URL : string = '';


  openGalleryBox : Subject<void> = new Subject<void>();

  @ViewChild('copyButton') copyButton : ElementRef<HTMLElement>

  constructor(
    private dataService : DataService,
    private shareService : ShareService
  ) { }

  ngOnInit() {
   
    this.dataService.setTitle(this.profile.name + ' ' + this.profile.surname);
    this.PROFILE_URL = environment.APP_PATH + '/s/' + this.profile._id;

    this.shareService.addButton('sendMail',{
      type: 'customButton',
      text: 'MAIL',
      icon: ['fas', 'envelope'],
      func: () => of({}).pipe(tap(() =>{ 
        var url = `mailto:?subject=${this.profile.name + ' ' + this.profile.surname}&body=${this.PROFILE_URL}`;
        window.open(url, '_blank');

      } 
      ))
    });

    this.shareService.addButton('copyLink',{
      type: 'customButton',
      text: 'My Custom Button',
      icon: ['', '_CHANGE_COPY_ICON'],
      params: {
      },
      func: () => of({}).pipe(tap(() => $('._COPY_').trigger('click')))
    });



  }

  openGallery() {
    this.openGalleryBox.next();
  }

  copied() {
    this.dataService.showAlert('success','','Copied to clipboard')
  }

  addCopyButton(){
    $('.sb-icon').last().addClass('fas fa-link');
  }

}
