import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';

import * as crypto from 'crypto-js';
import { windowWhen } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  token : any = false
  tokenId : any = false
  error : any = false;

  constructor(
    private dataService : DataService,
    private route: ActivatedRoute,
    private Auth : AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.dataService.setTitle('Home')
    this.route.params.subscribe (
      async (params) => {
        this.token = params['token']
        var encrypted =  crypto.AES.encrypt(this.token,'6YI7TFZVRT').toString();
        this.Auth.setURLToken(encrypted);

        const trackData : any = await this.dataService._trackData();
        this.checkURL(trackData.countryCode);
      }
    )
  }

  async checkURL(country) {

    const trackData : any = await this.dataService._trackData();

    this.dataService.__post('/check-url', {url : this.token, country : country, trackData : trackData})
    .subscribe(
      (success) => {
        console.log(success)
        if(!success.isUser){
          // Redirect to register
          this.router.navigateByUrl('/register');
          return false
        }
        if(success.isUser && !success.hasProfile){
          // Redirect to login
          this.router.navigateByUrl('/login');
          return false;
        }
        if(success.isUser && success.hasProfile.type=='u'){
          console.log('success.hasProfile.profile',success.hasProfile.profile)
          if(success.hasProfile.profile.match('http') == null){
            success.hasProfile.profile = 'http://' + success.hasProfile.profile;
          }
          window.location.href =  success.hasProfile.profile
          return false;
          
        }
        if(success.isUser && success.hasProfile){
          // Has Profile
          this.router.navigateByUrl(`${success.hasProfile.type}/${success.hasProfile.profile}`);
          return false;
        }

        this.error = 'Opps! Something went wrong! Please try again.'


      },
      (error) => {
        console.log('error',error)
        this.error = true;
        this.dataService.showAlert('error', 'Error', error)
      }
    )
  }

  

}
