import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/app/environments';
declare var currentSlide: any;


@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.css']
})
export class ImageGalleryComponent implements OnInit {

  constructor() { }

  IMAGE_URL : string = environment.APP_PATH;
  @Input('galleryPhotos') galleryPhotos : Array<any> = [];

  images: Array<any> = []
  
  ngOnInit(): void {
    this.images = this.galleryPhotos.map(g => {
      const ext = g.split('.')[1].toLowerCase()

      // For Images
      if(['gif', 'png', 'jpg', 'jpeg'].indexOf(ext) !== -1){

        if(g.match('http')){

          return { src: g, url: g, type: 'image' }

        }else{
          return { src: environment.ASSETS_URL + g, url: environment.ASSETS_URL + g, type: 'image' }
        }

      }


      // For docs
      // if(['pdf', 'docs', 'ppt', 'xml', ''].indexOf(ext) !== -1){ }

      // For Videos
      if(['mp4', '3gp', 'aiv', 'avi', 'mkv','mov','wmv','avchd','webm','pmeg-2','flv','f4v','swf','hevc'].indexOf(ext) !== -1){
        return { src: this.IMAGE_URL + '/assets/images/video.png', url: environment.ASSETS_URL + g, type: 'video' }
      }

      // For Audio
      if(['mp3', 'wav', 'flc', 'flac'].indexOf(ext) !== -1){
        return { src: this.IMAGE_URL + '/assets/images/mp3.png', url: environment.ASSETS_URL + g, type: 'audio' }
      }

      return { src: this.IMAGE_URL + '/assets/images/doc.png', url: environment.ASSETS_URL + g, type: 'docs' }
      


    });
  }

  selectSlide (index) {
    currentSlide(index)
  }

  openFile(path) {
    var link : any = document.createElement("a");
    link.download = path;
    link.setAttribute('target', '_blank');
    link.href = path;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
