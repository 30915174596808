import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ShareService } from 'ngx-sharebuttons';
import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';

import $ from 'jquery';



@Component({
  selector: 'app-v-card-profile',
  templateUrl: './v-card-profile.component.html',
  styleUrls: ['./v-card-profile.component.css']
})
export class VCardProfileComponent implements OnInit {

  @Input('profile') profile : any;
  IMAGE_URL : string = environment.ASSETS_URL;
  APP_URL : string = environment.APP_PATH;
  PROFILE_URL : string = '';
  mailType : string = 'CONTACT';

  openGalleryBox : Subject<void> = new Subject<void>();

  @ViewChild('closeModal') closeModal : ElementRef<HTMLElement>

  constructor(
    private dataService : DataService,
    private httpClient: HttpClient,
    private shareService : ShareService
  ) { }

  ngOnInit() {
    
    this.dataService.setTitle(this.profile.name + ' ' + this.profile.surname);
    this.PROFILE_URL = environment.APP_PATH + '/v/' + this.profile._id;
    

    this.shareService.addButton('sendMail',{
      type: 'customButton',
      text: 'MAIL',
      icon: ['fas', 'envelope'],
      func: () => of({}).pipe(tap(() =>{ 
        this.mailType = 'LINK';
        $('.sharesendMailButton').trigger('click')
       } 
      ))
    });


    this.shareService.addButton('copyLink',{
      type: 'customButton',
      text: 'My Custom Button',
      icon: ['', '_CHANGE_COPY_ICON'],
      func: () => of({}).pipe(tap(() => $('._COPY_').trigger('click')))
    });



  
  }

  copied() {
    this.dataService.showAlert('success','','Copied to clipboard')
  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

  onSendToMail(formData : any) {

    if(formData.value.email.trim() == '') {
      this.dataService.showAlert('error', 'error', 'Email Address is required')
      return false
    }
    if(!this.validateEmail(formData.value.email.trim())) {
      this.dataService.showAlert('error', 'error', 'Please enter valid email address')
      return false
    }

    formData.value.id = this.profile._id;
    if(this.mailType == 'LINK'){
      formData.value.link = this.PROFILE_URL;
    }

    this.dataService.__post('/send-card-by-email', formData.value, '#sendMail') 
    .subscribe(
      (success) => {
        this.dataService.showAlert('success', 'Success', 'Mail Sent Successfully')
        let el = this.closeModal.nativeElement
        el.click();
        this.mailType = 'CONTACT';
      },
      (error) => {
        this.mailType = 'CONTACT';
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }

  downloadVCard(){

    this.dataService.__post('/user/download/vCard',{id : this.profile._id})
    .subscribe(
      (success) => {
        
          const filepath = success.filepath;
          const imgUrl = environment.API_URL + filepath;
          window.location.assign(imgUrl);
          
      },
      (error) => {
        this.dataService.showAlert('error','Error',error);
      }
    )

  }

  addCopyButton(){
    $('.sb-icon').last().addClass('fas fa-link');
  }


  openGallery() {
    this.openGalleryBox.next();
  }

}
