import { Component, Input, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { environment } from "../environments";

import { Subject, Subscription } from "rxjs";

@Component({
  selector: "app-social-accounts",
  templateUrl: "./social-accounts.component.html",
  styleUrls: ["./social-accounts.component.css"],
})
export class SocialAccountsComponent implements OnInit {
  isLoading: any = false;
  medias: Array<any> = [];
  selectedSocialMedias: Array<any> = [];
  path: any = environment.ASSETS_URL;
  isMoved: any = false;

  subscription: Subscription;

  @Input() _removeSocialAccount: Subject<any>;
  @Input() _moveSocialAccount: Subject<any>;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.subscription = this.dataService
      .getSelectedSocialMedias()
      .subscribe((data) => {
        this.selectedSocialMedias = data;
      });

    this.getMedias();

    this._removeSocialAccount.subscribe((v) => {
      console.log("V", v);
      this.selectSocialMedia(0, v);
    });
    this._moveSocialAccount.subscribe((media) => {
      this.moveMedia(media);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setActive() {
    var localSelectedMedia = JSON.parse(
      this.dataService.getTemp("avctiveMedias")
    );
    this.selectedSocialMedias = localSelectedMedia;
    var active = localSelectedMedia.map((x) => x._id);
    if (!active.length) return false;
    this.medias.forEach((x) => {
      if (active.indexOf(x._id) !== -1) {
        x.isSelected = true;
      }
    });
  }

  getMedias() {
    this.isLoading = true;
    this.dataService.__post("/medias", {}).subscribe(
      (success) => {
        this.medias = success;
        this.isLoading = false;
        this.setActive();
      },
      (error) => {
        this.dataService.showAlert("error", "Error", error);
        this.isLoading = false;
      }
    );
  }

  selectSocialMedia(i, media) {
    var item = media;
    var indexX = this.medias.findIndex((x) => x._id == media._id);

    if (item.isSelected) {
      this.medias[indexX].isSelected = false;
      console.log(this.selectedSocialMedias);
      const index = this.selectedSocialMedias.findIndex(
        (x) => x._id == item._id
      );
      this.selectedSocialMedias.splice(index, 1);
    } else {
      this.medias[indexX].isSelected = true;
      this.selectedSocialMedias.push({
        name: item.name,
        _id: item._id,
        icon: item.icon,
        mediaUrl: item.mediaUrl,
        placeholder: item.placeholder,
      });
    }
    this.dataService.setSelectedSocialMedias(this.selectedSocialMedias);
  }

  moveMedia(media) {

    if(!media.isLoaded) {

      const indexX = this.selectedSocialMedias.findIndex(
        (x) => x._id == media._id
      );
  
      var item = this.medias.find((x, i) => {
        return i == indexX-1
      })
  
      if(indexX !== 0 && media.type == 'up') {
        this.selectedSocialMedias.splice(indexX,1)
        this.selectedSocialMedias.splice(indexX-1,0,media)
      }
      if(indexX !== this.selectedSocialMedias.length-1 && media.type == 'down') {
        this.selectedSocialMedias.splice(indexX,1)
        this.selectedSocialMedias.splice(indexX+1,0,media)
      }
  
      this.dataService.setSelectedSocialMedias(this.selectedSocialMedias);
      media.isLoaded = true
    }
   
  }
}
