import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { of, Subject } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/app/environments';
import { ClipboardService } from 'ngx-clipboard'
import { HttpClient } from '@angular/common/http';
import { ShareService } from 'ngx-sharebuttons';


import $ from 'jquery';
import { tap } from 'rxjs/operators';



@Component({
  selector: 'app-business-profile-card',
  templateUrl: './business-profile-card.component.html',
  styleUrls: ['./business-profile-card.component.css']
})
export class BusinessProfileCardComponent implements OnInit {

  @Input('profile') profile : any;
  @Input('overlapClass') overlapClass : string = '';

  IMAGE_URL : string = environment.ASSETS_URL;
  APP_URL : string = environment.APP_PATH;
  PROFILE_URL : string = '';
  mailType : string = 'CONTACT';
  
  openGalleryBox : Subject<void> = new Subject<void>();

  @ViewChild('closeModal') closeModal : ElementRef<HTMLElement>
  
  constructor(
    private dataService : DataService,
    private _clipboardService: ClipboardService,
    private httpClient: HttpClient,
    private shareService : ShareService

  ) { }

  ngOnInit() {
    this.dataService.setTitle(this.profile.company)
    this.PROFILE_URL = environment.APP_PATH + '/b/' + this.profile._id;
    
    this.shareService.addButton('sendMail',{
      type: 'customButton',
      text: 'MAIL',
      icon: ['fas', 'envelope'],
      func: () => of({}).pipe(tap(() =>{ 
        this.mailType = 'LINK';
        $('.sharesendMailButton').trigger('click')
       } 
      ))
    });

    
    this.shareService.addButton('copyLink',{
      type: 'customButton',
      text: 'My Custom Button',
      icon: ['', '_CHANGE_COPY_ICON'],
      params: {
      },
      func: () => of({}).pipe(tap(() => $('._COPY_').trigger('click')))
    });

    
 
  }

  copied() {
    this.dataService.showAlert('success','','Copied to clipboard')
  }

  getUrl(url){
    
    if(url && url.match('http')){
      return url;
    }else {
      return `http://${url}`;
    }

  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

  onSendToMail(formData : any) {

    if(formData.value.email.trim() == '') {
      this.dataService.showAlert('error', 'error', 'Email Address is required')
      return false
    }
    if(!this.validateEmail(formData.value.email.trim())) {
      this.dataService.showAlert('error', 'error', 'Please enter valid email address')
      return false
    }

    formData.value.id = this.profile._id;
    if(this.mailType == 'LINK'){
      formData.value.link = this.PROFILE_URL;
    }

    this.dataService.__post('/send-business-card-by-email', formData.value, '#sendMail') 
    .subscribe(
      (success) => {
        this.dataService.showAlert('success', 'Success', 'Mail Sent Successfully')
        let el = this.closeModal.nativeElement
        el.click();
        this.mailType = 'CONTACT';
      },
      (error) => {
        this.mailType = 'CONTACT';
        this.dataService.showAlert('error', 'Error', error)
      }
    )

  }

  downloadVCard(){

    this.dataService.__post('/user/download/businessCard',{id : this.profile._id})
    .subscribe(
      (success) => {
        

        const filepath = success.filepath;
        const imgUrl = environment.API_URL + filepath;
        window.location.assign(imgUrl);
        
        // const filepath = success.filepath;
        // const imgUrl = environment.API_URL + filepath;
        // const imgName = imgUrl.substr(imgUrl.lastIndexOf('/') + 1);
        // this.httpClient.get(imgUrl, {responseType: 'blob' as 'json'})
        // .subscribe((res: any) => {
        //   const file = new Blob([res], {type: res.type});
        //   // IE
        //   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveOrOpenBlob(file);
        //     return;
        //   }
        //   const blob = window.URL.createObjectURL(file);
        //   const link = document.createElement('a');
        //   link.href = blob;
        //   link.download = imgName;
        //   // Version link.click() to work at firefox
        //   link.dispatchEvent(new MouseEvent('click', {
        //     bubbles: true,  
        //     cancelable: true,
        //     view: window
        //   }));
        //   setTimeout(() => { // firefox
        //     window.URL.revokeObjectURL(blob);
        //     link.remove();
        //   }, 100);
        // });

       
 
      },
      (error) => {
        this.dataService.showAlert('error','Error',error);
      }
    )

  }

  addCopyButton(){
    $('.sb-icon').last().addClass('fas fa-link');
  }


  openGallery() {
    this.openGalleryBox.next();
  }

}
