import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { environment } from '../environments';



import { Subject,Subscription } from 'rxjs';


@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.css']
})
export class SocialComponent implements OnInit {

  isLoading: any = false;
  subscription: Subscription;
  path: any = environment.ASSETS_URL
  socialProfile : any = false;
  selectedSocialMedias : Array<any> = [];
  galerryImages : Array<any> = [];
  selectedImage : any = false;
  text : string = '';

  profileImage : File;

  _removeSocialAccount: Subject<boolean> = new Subject();
  _moveSocialAccount: Subject<boolean> = new Subject();
  _removeSocialAccountUpdate: Subject<boolean> = new Subject();


  constructor(
    private dataService: DataService,
    private router : Router
  ) { 


    this.subscription = this.dataService.getSelectedSocialMedias()
    .subscribe((data) => {
      this.selectedSocialMedias = data;
    })


  }


  ngOnInit() {

    this.dataService.setTitle('Social Networks Profiles')
    this.getSocialProfile();
    this.dataService.setTemp('avctiveMedias',JSON.stringify([]));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getSocialProfile () {
    this.dataService.__post('/social-card', {})
    .subscribe(
      (success) => {
        this.socialProfile = success
        this.text = `${environment.APP_PATH}/s/${success._id}`;
        if(this.socialProfile.socialMedias){
          this.dataService.setTemp('avctiveMedias',JSON.stringify(this.socialProfile.socialMedias))
          this.dataService.setSelectedSocialMedias(this.socialProfile.socialMedias);
        }
      },
      (error) => {
        console.log('Error : ', error)
      }
    )
  }


  onFileSelect(files : Array<File>){
    this.profileImage = files[0];
  }

  onGallerySelect(event){
    this.galerryImages = event;
  }

  onCreate(formData, type = 'create') {


    var data = new FormData();
    if(type == 'create'){

      if(!this.profileImage){
        this.dataService.showAlert('error','Error','Please select your profile picture');
        return false;
      }
      data.append('profile', this.profileImage)

    } else {

      if(this.profileImage){
        data.append('profile', this.profileImage);
      }

    }

    var totalSize = 0;

    for (var i = 0; i < this.galerryImages.length; i++) {
      if(!this.galerryImages[i].original){
        totalSize += this.galerryImages[i].size;
      } 
    }


    if((totalSize / 1048576) > 20){
      this.dataService.showAlert('error','Error',`Your selected files size is ${(totalSize / 1048576).toFixed(2)}MB and maximum allowed 20MB`);
      return false;
    }

    
    data.append('name', formData.value.name)
    data.append('surname', formData.value.surname)
    data.append('aboutMe', formData.value.aboutMe);

    var oldGalleryImages = [];

    this.galerryImages.map(file => {
      
      if(file.original){
        oldGalleryImages.push(file.original);
      }else{
        data.append('gallery',file)
      }

    });

    if(oldGalleryImages.length !== 0){
      data.append('oldGalleryImages',JSON.stringify(oldGalleryImages));
    }

    


    // data.append('galleryImages',this.profileImage)

    var social : Array<any> = []

    for (var key in this.selectedSocialMedias) {
      social.push({
        name : this.selectedSocialMedias[key].name,
        placeholder : this.selectedSocialMedias[key].placeholder,
        icon : this.selectedSocialMedias[key].icon,
        mediaUrl : this.selectedSocialMedias[key].mediaUrl,
        url : formData.value[this.selectedSocialMedias[key]._id],
        _id : this.selectedSocialMedias[key]._id
      })
    }

    if(!social.length){
      this.dataService.showAlert('error','Invalid Value','Please select atleast 1 social media')
      return false;
    }
 
    data.append('socialMedias',JSON.stringify(social))

    this.dataService.__post('/profile/social/create', data, '#addSocial')
    .subscribe(
      (success) => {
        this.socialProfile = success;
        this.dataService.setSelectedSocialMedias(success.socialMedias);
        window.location.reload();
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )
  }

  onImageChange(event) {
    this.selectedImage = event.target.files[0]
  }

  deleteProfile(){

    if(!confirm('Are you sure you want to delete this social profile!')) return false;

    this.dataService.__post('/profile/social/delete',{},'#delete-profile')
    .subscribe(
      success => {
        this.dataService.showAlert('success','Success','Social Profile Deleted Successfully');
        this.socialProfile = false;
      },
      error => {
        this.dataService.showAlert('error','Error','Oops! Failed to delete your account')
      }
    )
  }

  listOrderChanged(event : any){
    this.selectedSocialMedias = [...event];
  }

  removeSocialAccount(item, type = 'create'){
    item.isSelected = true;
    if(type == 'create'){
      this._removeSocialAccount.next(item);
    }else{
      this._removeSocialAccountUpdate.next(item);
    }
  }

  moveSocialAccount(item, type = 'up') {
    item['isLoaded'] = false
    item['type'] = type
    this._moveSocialAccount.next(item);
  }

}
