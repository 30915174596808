import { Component, Input, OnInit } from '@angular/core';

// import { Lightbox } from 'ngx-lightbox';
import { environment } from 'src/app/environments';

import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-gallery-viewer',
  templateUrl: './gallery-viewer.component.html',
  styleUrls: ['./gallery-viewer.component.css']
})
export class GalleryViewerComponent implements OnInit {

  IMAGE_URL : string = environment.ASSETS_URL;
  items: GalleryItem[];

  @Input() openGalleryBox: Observable<void>;
  private gallerySubscription: Subscription;


  
  @Input('galleryPhotos') galleryPhotos : Array<any> = [];
  // gallery: Array<any> = [];

  constructor(
    private gallery: Gallery,
     private lightbox: Lightbox
  ) { }

  ngOnInit(): void {

    // Creat gallery items
    this.items = this.galleryPhotos.map(g => {
      const ext = g.split('.')[1]
      if(ext == 'jpg' || ext == 'jpeg' || ext == 'png') {
        return new ImageItem({ src: this.IMAGE_URL + g, thumb: this.IMAGE_URL + g })
      } else if (ext == 'pdf') {
        return new ImageItem({ src: this.IMAGE_URL + '/images/pdf2.png', thumb: this.IMAGE_URL + '/images/pdf2.png' })
      } else {
        return new ImageItem({ src: this.IMAGE_URL + '/images/video2.com', thumb: this.IMAGE_URL + '/images/video2.com' })
      }
    });
    const lightboxRef = this.gallery.ref('lightbox');


    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);


    this.gallerySubscription = this.openGalleryBox.subscribe(() => this.lightbox.open(0,'lightbox',{panelClass : 'fullscreen'}) );

    // this.galleryPhotos.map(g => {
    //   this.gallery.push({
    //     src: this.IMAGE_URL + g
    //  })
    // });

  }


  ngOnDestroy() {
    this.gallerySubscription.unsubscribe();
  }


  // open(index: number): void {
  //   // open lightbox
  //   this._lightbox.open(this.gallery, index,
  //     { 
  //       wrapAround: true, 
  //       showImageNumberLabel: true ,
  //       centerVertically : true,
  //       alwaysShowNavOnTouchDevices : true,
  //       showZoom : true
  //     }
  //   );
  // }

}
