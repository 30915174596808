import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private _service : DataService,private _route : Router) { }

  Auth : any = false;
  user : any = false;

  setUser(user) {
    this.user = user
    localStorage.setItem("user",JSON.stringify(user));
  }
 
  getUser() {
    this.user = localStorage.getItem('user');
    return (this.user)? JSON.parse(this.user) : false;
  }

  _isLoggedin(){
    return this._service.__post(`/checkAuth`,{})
  }

  setToken(token : string){
    localStorage.setItem('X-Authenticated-user',token);
  }

  getToken(){
    return localStorage.getItem('X-Authenticated-user');
    
  }

  setURLToken(token : string){
    localStorage.setItem('URLToken',token);
  }
  getURLToken(){
    return localStorage.getItem('URLToken');
    
  }

  setURL(url){
    localStorage.setItem('URL',JSON.stringify(url));
  }
  getURL(){
    return JSON.parse(localStorage.getItem('URL'));
  }

  isAuth(){
    return ( this.getToken() ? true : false);
  }

  logout(){
    localStorage.clear();
    this.user = false
    this._route.navigateByUrl('/login');   
  }

}
