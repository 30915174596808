import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  token : string = '';

  constructor(
    private dataService : DataService,
    private router : Router
  ) { }

  ngOnInit(): void {

    if(!this.dataService.getTemp('TC')){
      this.router.navigateByUrl('/');
      return;
    }

    this.dataService.__post('/subscription/activation',{token :this.dataService.getTemp('TC')})
    .subscribe(
      (success) => {
        this.dataService.showAlert('success','Payment Success!','');
        this.router.navigateByUrl('profile');
        localStorage.removeItem('TC');
        return;
      },
      (error) => {
        this.dataService.showAlert('error','Error',error);
        setTimeout(() =>{
          this.router.navigateByUrl('profile');
          localStorage.removeItem('TC');
        },2000)
      }
    )

  }

}
