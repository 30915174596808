import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, NgForm } from '@angular/forms';
import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './layouts/header/header.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { ProfileComponent } from './profile/profile.component';
import { SocialProfileComponent as USerSocialProfileComponent } from './users/social-profile/social-profile.component';
import { BusinessCardComponent } from './business-card/business-card.component';
import { VCardComponent } from './v-card/v-card.component';
import { VcardProfileComponent } from './users/vcard-profile/vcard-profile.component';
import { BusinessProfileComponent } from './users/business-profile/business-profile.component';
import { SocialComponent } from './social/social.component';
import { SocialAccountsComponent } from './social-accounts/social-accounts.component';
import { SocialProfileCardComponent } from './cards/social-profile-card/social-profile-card.component';
import { BusinessProfileCardComponent } from './cards/business-profile-card/business-profile-card.component';
import { VCardProfileComponent } from './cards/v-card-profile/v-card-profile.component';
import { LoaderComponent } from './loader/loader.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgxKjuaModule } from 'ngx-kjua';
import { QrComponent } from './qr/qr.component';
import { ResetLinkComponent } from './reset-link/reset-link.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SettingsComponent } from './settings/settings.component';
import { StripeModule } from "stripe-angular"
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { HighchartsChartModule } from 'highcharts-angular';
import { ChartsModule } from 'ng2-charts';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { TooltipModule } from 'ng2-tooltip-directive';
import { VisitorCountComponent } from './visitor-count/visitor-count.component';

import { NgxSortableModule } from 'ngx-sortable';
import { SuccessComponent } from './payment/success/success.component';
import { CancelComponent } from './payment/cancel/cancel.component';
import { QrCodeComponent } from './qr-code/qr-code.component'
import { ClipboardModule } from 'ngx-clipboard';

import { ImageCropperModule } from 'ngx-image-cropper';
import {NgxImageCompressService} from 'ngx-image-compress';
import { GalleryUploaderComponent } from './gallery/gallery-uploader/gallery-uploader.component';


// import { LightboxModule } from 'ngx-lightbox';
import { GalleryViewerComponent } from './gallery/gallery-viewer/gallery-viewer.component';


import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { MasterAuthComponent } from './master-auth/master-auth.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ImageGalleryComponent } from './gallery/image-gallery/image-gallery.component';
import { UrlRedirectComponent } from './url-redirect/url-redirect.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    RegisterComponent,
    DefaultComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    ProfileComponent,
    BusinessCardComponent,
    VCardComponent,
    VcardProfileComponent,
    BusinessProfileComponent,
    USerSocialProfileComponent,
    SocialComponent,
    SocialAccountsComponent,
    SocialProfileCardComponent,
    BusinessProfileCardComponent,
    VCardProfileComponent,
    LoaderComponent,
    NotFoundComponent,
    QrComponent,
    ResetLinkComponent,
    ForgotPasswordComponent,
    DropzoneComponent,
    SettingsComponent,
    VisitorCountComponent,
    SuccessComponent,
    CancelComponent,
    QrCodeComponent,
    GalleryUploaderComponent,
    GalleryViewerComponent,
    MasterAuthComponent,
    StatisticsComponent,
    ImageGalleryComponent,
    UrlRedirectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot(),
    NgxKjuaModule,
    NgxDropzoneModule,
    StripeModule.forRoot("pk_live_51IZHKfIHBUjxmoNTWnVkgCzGu8XCb94wcvCcHq0CiBwDGpk02HuB3Y5DjQEB1Lp4umdzH7YRlDa0zMHNGxcJfKLK00BtwycFu1"),
    // StripeModule.forRoot("pk_test_51Kt3rNH9rAL64Q2MM2y9KrOvA6jPXeMPwndwGEy0WU9im9itJ2oMMpivYZp01RMZk0WvfPBTm0vAbv02WS6fPTev00pv5DaFwb"),
    ShareButtonsModule.withConfig({}),
    ShareIconsModule,
    TooltipModule,
    NgxSortableModule,
    GooglePlaceModule,
    ClipboardModule,
    ImageCropperModule,
    GalleryModule,
    LightboxModule,
    HighchartsChartModule,
    ChartsModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  providers: [NgxImageCompressService],
  bootstrap: [AppComponent]
})


export class AppModule {}