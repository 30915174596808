import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-gallery-uploader',
  templateUrl: './gallery-uploader.component.html',
  styleUrls: ['./gallery-uploader.component.css']
})
export class GalleryUploaderComponent implements OnInit {

  files: any = [];
  selectedFiles: Array<any> = [];

  @Output() onGallerySelect = new EventEmitter();
  @Input('defaultImages') defaultImages: Array<any> = [];

  IMAGE_URL: string = environment.APP_PATH;

  constructor() { }

  ngOnInit(): void {

    if (this.defaultImages) {

      this.defaultImages.map(g => {
        const path = this.getPath(g)
        this.selectedFiles.push(path.icon);
        this.files.push(path);

      });


    }

    console.log('defaultImages',this.defaultImages)

  }

  getPath(g) {

    const ext = g.split('.')[1].toLowerCase();


    // For Images
    if (['gif', 'png', 'jpg', 'jpeg'].indexOf(ext) !== -1) {
      return (g.match('http') !== null ? {icon : g, original : g} : {icon : environment.ASSETS_URL + g , original : g} ) 
    }

    // For Videos
    if (['mp4', '3gp', 'aiv', 'avi', 'mkv','mov','wmv','avchd','webm','pmeg-2','flv','f4v','swf','hevc'].indexOf(ext) !== -1) {
      var video = this.IMAGE_URL + '/assets/images/video.png';
      return {icon : video, original : g}
    }

    // For Audio
    if (['mp3', 'wav', 'flc', 'flac'].indexOf(ext) !== -1) {
      var audio = this.IMAGE_URL + '/assets/images/mp3.png';
      return {icon : audio, original : g}

    }
    
    return {icon : this.IMAGE_URL + '/assets/images/doc.png', original : g}
    

  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
    console.log('this.files',this.files)
    this.setPreview();
  }

  setPreview() {

    this.selectedFiles = [];

    this.files.map(fileObject => {

      if(fileObject.original){
        var file  = fileObject.original;
      } else{
        var file = fileObject;
      }

      console.log('file',typeof file)
      

     
      if (typeof file !== 'string') {
        var reader = new FileReader();
        
        const ext = file.name.split('.')[1].toLowerCase()


        if (['gif', 'png', 'jpg', 'jpeg'].indexOf(ext) !== -1) {
          reader.readAsDataURL(file);
          reader.onload = (_event) => {
            this.selectedFiles.push(reader.result);
          }
        } else {
          this.selectedFiles.push(this.getPath(file.name).icon);
        }

      } else {

        this.selectedFiles.push(this.getPath(file).icon);
        
      }

    });

    this.onGallerySelect.emit(this.files);
  }

  onRemove(i) {
    this.files.splice(i, 1);
    this.setPreview();
  }

}
